/* global body padding */
body {
  padding-top:    20px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  body {
    padding-top:    50px;
    padding-bottom: 50px;
  }
}

/* global spacing overrides */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
}
hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

.navbar-fixed-top,
.navbar-static-top {
  border-bottom: 0;
}

.login {
  margin: 5% auto 50px;
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  width: 20rem;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  box-sizing: border-box;
}

.login .title {
  margin-bottom: 1.5rem;
}

.login .submit-box {
  margin: 2rem 0 1rem 0;
}

.login .submit-btn {
  text-align: center;
  display: inline-block;
  min-width: 140px;
  box-sizing: border-box;

  padding: 0.8rem 2rem;
  font-size: 0.8rem; }

.login .alert {
  border-radius: 0; }

.login .alert p {
  margin-bottom: 0; }

.dashhead-toolbar-item .icon {
  font-size: 1.2rem; }

.dashhead-toolbar-item  .badge {
  position: absolute;
  top: -0.2em;
  left: 1.2em;
  border-radius: 0.25em;
  font-size: 0.4em;
  padding: 2px 2px; }

.dashhead-toolbar-item .toggle {
  font-size: 0.9rem;}

.sidebar-brand-logo {
  width: 50%;
  height: auto;}

.sidebar-brand-version {
  font-size: 1.0rem; }

.toolbar {
  margin-bottom: 0.25rem !important; }

.alarm-name {
  width: 20%;
  display: flex !important;
}

.alarm-notification {
  width: 20%;
  display: flex !important;
}

.alarm-condition {
  width: 60%;
  display: flex !important;
}

.paymentbutton-premium {
  font-weight: bold;
  color: white;
  background-color: #9F86FF; }

.paymentcard-free .card-header {
  font-weight: bold;
  color: white;
  background-color: #1BC98E; }
.paymentcard-basic .card-header {
  font-weight: bold;
  color: white;
  background-color: #1997c6; }
.paymentcard-premium .card-header {
  font-weight: bold;
  color: white;
  background-color: #9F86FF; }
.paymentcard-free .btn {
  font-weight: bold;
  color: white;
  background-color: #1BC98E; }
.paymentcard-basic .btn {
  font-weight: bold;
  color: white;
  background-color: #1997c6; }
.paymentcard-premium .btn {
  font-weight: bold;
  color: white;
  background-color: #9F86FF; }

.infocard-primary .card-header {
  font-weight: bold;
  color: #fff;
  background-color: #1997c6; }

.infocard-success .card-header {
  font-weight: bold;
  color: #fff;
  background-color: #1BC98E; }

.infocard-info .card-header {
  font-weight: bold;
  color: #fff;
  background-color: #9F86FF; }

.infocard-warning .card-header {
  font-weight: bold;
  color: #fff;
  background-color: #E4D836; }

.infocard-danger .card-header {
  font-weight: bold;
  color: #fff;
  background-color: #E64759; }

.itemrow label {
  font-weight: bold; }

.input-hint {
  font-weight: bold;
  font-size: small;
  color: #1997c6; }

.btn-text {
  border: none;
  display: inline-block;
  text-decoration: none; }
  .btn-text:focus, .btn-text.focus {
    box-shadow: none;
    border: none; }

.color-primary {
  color: #1997c6; }

.color-success {
  color: #1BC98E; }

.color-info {
  color: #9F86FF; }

.color-warning {
  color: #E4D836; }

.color-danger {
  color: #E64759; }

/* Place */
.place-search-input-container {
  position: relative;
}

.place-search-input,
.place-search-input:focus,
.place-search-input:active {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.place-clear-button,
.place-clear-button:active,
.place-clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.place-autocomplete-container {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.place-suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer; }

.place-suggestion-item--active {
  background-color: #fafafa; }

.place-dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px; }

.place-error-message {
  color: red; }

.slider {
  flex-grow: 1;
  margin-bottom: 1rem !important; }

.slider-thermostat .rangeslider__fill {
  background-color: #ef5350; }

.slider-airconditioner .rangeslider__fill {
  background-color: #1e88e5; }

.toolbar-dropdown {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.card-thermostat {
  border-color: #1997c6;
}
.card-thermometer {
  border-color: #1BC98E;
}
.card-generalio {
  border-color: #9F86FF;
}
.card-heatcable {
  border-color: #E4D836;
}
.card-farmcontroller {
  border-color: #E4D836;
}
.card-smartsensor {
  border-color: #E4D836;
}
.card-gateway {
  border-color: #b3d9ff;
}
.card-unknown {
  border-color: #b3d9ff;
}
.statcard-thermostat {
  background-color: #1997c6;
}
.statcard-thermometer {
  background-color: #1BC98E;
}
.statcard-generalio {
  background-color: #9F86FF;
}
.statcard-heatcable {
  background-color: #E4D836;
}
.statcard-farmcontroller {
  background-color: #E4D836;
}
.statcard-smartsensor {
  background-color: #E4D836;
}
.statcard-gateway {
  background-color: #b3d9ff;
}
.statcard-unknown {
  background-color: #b3d9ff;
}

